import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPatientRecords } from '../../actions/patientActions' // Update the import path as needed

function HomeLogo() {
  const [selectedLocation, setSelectedLocation] = useState('')
  const dispatch = useDispatch()
  const { metadata, error } = useSelector((state) => state.patient)

  useEffect(() => {
    if (selectedLocation) {
      dispatch(fetchPatientRecords({ location: selectedLocation }))
    }
  }, [dispatch, selectedLocation])

  const handleLocationChange = (e) => {
    setSelectedLocation(e.target.value)
  }

  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-gray-100 p-6 text-center">
      <div className="mb-6">
        <select
          id="location"
          value={selectedLocation}
          onChange={handleLocationChange}
          className="transform appearance-none rounded-md border border-transparent bg-gray-900 p-2 text-center text-white shadow-lg transition-all duration-300 ease-in-out hover:scale-105 focus:outline-none focus:ring-2 focus:ring-brandSecondary"
        >
          <option value="">All Locations</option>
          <option value="Nakuru">Nakuru</option>
          <option value="Kibra">Nairobi (Kibera)</option>
          <option value="Machakos(Athi River)">Machakos (Athi-River)</option>
          <option value="Nyandarua">Nyandarua</option>
          <option value="Kakamega">Kakamega</option>
          <option value="Narok">Narok</option>
          {/* <option value="Kisumu">Kisumu</option> */}
          <option value="Nairobi (TradeFair)">Nairobi (TradeFair)</option>
          <option value="Trans-Nzoia">Trans-Nzoia (Kitale)</option>
          <option value="Meru">Meru</option>
          <option value="Mombasa">Mombasa</option>
          <option value="Garissa">Garissa</option>
          <option value="Kirinyaga">Kirinyaga</option>
          {/* <option value="Siaya">Siaya</option> */}
          <option value="mathare">Nairobi (mathare)</option>
          <option value="Machakos-vota">Machakos (vota)</option>
          {/* <option value="Machakos(Stadium)">Machakos (Stadium)</option> */}
          <option value="Bungoma">Bungoma</option>
          <option value="Kiambu-githurai">Kiambu (Githurai)</option>
          <option value="Kajiado">Kajiado (Rongai)</option>
          <option value="Nairobi-kabete">Nairobi (Kabete)</option>
          <option value="Bomet">Bomet</option>
          {/* <option value="Delta">Ubuluku, Delta State</option>
          <option value="Edo">Uromi, Edo State</option> */}
          <option value="Kisii">Kisii</option>
          {/* <option value="Migori">Migori</option> */}
          {/* <option value="Nairobi(Langata)">Nairobi (Langata)</option> */}
          <option value="Taita-Taveta">Taita-Taveta</option>
          <option value="Murang'a">Murang'a</option>
          <option value="Homa Bay">Homa Bay</option>
          <option value="Turkana">Turkana</option>
          <option value="Kericho(Kapcheluch)">Kericho</option>
          <option value="Tharaka-Nithi">Tharaka-Nithi</option>
          <option value="Nyamira">Nyamira</option>
          <option value="Uasin_Gishu">Uasin Gishu</option>
          <option value="Kiambu(Kamandura)">Kiambu</option>
          <option value="Kwale">Kwale</option>
          <option value="Isiolo">Isiolo</option>
          <option value="Baringo">Baringo</option>
        </select>
      </div>

      {/* Display the number directly */}
      <p className="mb-4 font-digital text-5xl font-bold text-green-600">
        {error
          ? `Error: ${error}`
          : selectedLocation && metadata.total // Show number only if a location is selected
            ? `${metadata.total}`
            : 'Select a location'}
      </p>

      <img
        src="https://healthcamp-products.s3.us-east-2.amazonaws.com/Frame+6322.svg"
        alt="Home Logo"
        className="mb-4 w-1/2 max-w-4xl"
      />

      <p className="text-lg text-gray-700">
        You have successfully accessed the site containing sensitive patient
        information. Please proceed with caution and ensure that you have the
        necessary permissions to view this content.
      </p>
    </div>
  )
}

export default HomeLogo
